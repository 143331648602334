<template>
  <nav class="navbar is-success" aria-label="main navigation" role="navigation">

    <div class="container is-max-desktop px-2">

      <div class="navbar-brand">
        <div class="navbar-item is-size-4 is-family-monospace">
          Notas
        </div>

        <div
            @click.prevent="showModalNav = !showModalNav"
            role="button"
            class="navbar-burger"
            :class="{ 'is-active' : showModalNav }"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            ref="navBarBurgerRef"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </div>

      </div>

      <div
          id="navbarBasicExample"
          class="navbar-menu"
          :class="{ 'is-active' : showModalNav }"
          ref="navBarMenuRef"
      >
        <div class="navbar-start">

          <button
              v-if="storeAuth.user.id"
              @click="logOutFechaMenuMovel"
              class="button is-small is-info mt-3 ml-3"
          >
            Log Out - {{ storeAuth.user.email }}
          </button>

        </div>

        <div class="navbar-end">

          <RouterLink
              @click="showModalNav = false"
              to="/"
              class="navbar-item"
              active-class="is-active"
          >Notes
          </RouterLink>

          <RouterLink
              @click="showModalNav = false"
              to="/stats"
              class="navbar-item"
              active-class="is-active"
          >Stats
          </RouterLink>

        </div>
      </div>
    </div>

  </nav>
</template>

<script setup>
// IMPORTS
import {ref} from 'vue'
import { onClickOutside } from '@vueuse/core'
import { useStoreAuth } from "@/stores/storeAuth";

// STORE
const storeAuth = useStoreAuth()

// REFs
let showModalNav = ref(false)
const navBarBurgerRef = ref(null)

// Click ouside do close navBarMenuRef
const navBarMenuRef = ref(null)
onClickOutside(navBarMenuRef, () => {
  showModalNav.value = false
}, {
  ignore: [navBarBurgerRef]
})

// LOGOUT E FECHA A BARRA no movel
const logOutFechaMenuMovel = () => {
  showModalNav.value = false
  storeAuth.logoutUser()
}

</script>

<style>
@media (max-width: 1023px) {
  .navbar-menu {
    position: absolute;
    left: 0;
    width: 100%;
  }
}
</style>
