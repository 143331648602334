<template>
  <div class="card p-4 mb-5"
  :class="`has-background-${ props.bgColor}-dark`"
  >
    <label
        v-if="labelText"
        for=""
        class="has-text-white label"
    >
      {{ labelText }}
    </label>

    <div class="field">
      <div class="control">
          <textarea
              :value="modelValue"
              @input="$emit('update:modelValue', $event.target.value)"
              class="textarea"
              ref="textareaRef"
              maxlength="100"
              :placeholder="props.placeHolder"
              v-Autofocus
          >
          </textarea>
      </div>
    </div>

    <div class="field is-grouped is-grouped-right">
      <div class="control">
        <slot name="buttons"></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
/*
  imports
*/
import {ref} from 'vue'
import {vAutofocus} from "@/directives/vAutofocus";

/*
  props
 */

// eslint-disable-next-line no-unused-vars,no-undef
const props = defineProps({
  modelValue: {
    type: String,
    required: true
  },
  bgColor: {
    type: String,
    default: 'success'
  },
  placeHolder: {
    type: String,
    default: 'Type something...'
  },
  labelText: {
    type: String,
  }
})

/*
  emits
 */
// eslint-disable-next-line no-unused-vars,no-undef
const emit = defineEmits(['update:modelValue'])


/*
  focusTextArea
*/

const textareaRef = ref(null)

const focusTextArea = () => {
  textareaRef.value.focus()
}

// eslint-disable-next-line no-undef
defineExpose({
  focusTextArea
})

</script>