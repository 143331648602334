<template>
  <div class="modal is-active p-4">
    <div class="modal-background"></div>
    <div class="modal-card" ref="modalCardRef">
      <header class="modal-card-head">
        <p class="modal-card-title">Delete Note?</p>
        <button
            @click="closeModal"
            class="delete"
            aria-label="close"
        ></button>
      </header>
      <section class="modal-card-body">
        Are you sure you want to delete note?
      </section>
      <footer class="modal-card-foot is-justify-content-flex-end">
        <button
            class="button"
            @click="closeModal"
        >Cancel</button>
        <button
            @click="storeNotes.deleteNote(noteId)"
            class="button is-danger"
        >Delete</button>
      </footer>
    </div>
  </div>
</template>

<script setup>
// IMPORTS
import {onMounted, onUnmounted, ref} from "vue";
import {onClickOutside} from "@vueuse/core";
import {useStoreNotes} from "@/stores/StoreNotes";

// PROPS
// eslint-disable-next-line no-unused-vars,no-undef
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  noteId: {
    type: String,
    required: true
  }
})

// EMITS
// eslint-disable-next-line no-unused-vars,no-undef
const emit = defineEmits(['update:modelValue'])

// STORE
const storeNotes = useStoreNotes()

// CLOSE MODAL
const closeModal = () => {
  emit('update:modelValue', false)
}

// Click ouside do close navBarMenuRef
const modalCardRef = ref(null)
onClickOutside(modalCardRef, closeModal)

// TECLADO ESQ FECHAR
const HandleKeyboard = e => {
  // console.log('activou 1 x sem o unount multiplica')
  if (e.key === 'Escape') closeModal()
}
onMounted(() => {
  document.addEventListener('keyup', HandleKeyboard)
})

onUnmounted(() => {
  document.removeEventListener('keyup', HandleKeyboard)
})

</script>