import {defineStore} from 'pinia'

import {collection, onSnapshot, doc, deleteDoc, updateDoc, addDoc, query, orderBy} from "firebase/firestore";
import {db} from '@/js/firebase'
import {useStoreAuth} from '@/stores/storeAuth'

// directo a collecao
// const notesCollectionRef = collection(db, 'notes')
// const notesCollectionQuery = query(notesCollectionRef, orderBy('date', 'desc'))

let notesCollectionRef
let notesCollectionQuery
let getNotesSnapshot = null

export const useStoreNotes = defineStore('storeNotes', {
    state: () => {
        return {
            notes: [],
            notesLoaded: false
        }
    },
    actions: {
        init() {
            // eslint-disable-next-line no-unused-vars
            const storeAuth = useStoreAuth()

            notesCollectionRef = collection(db, 'users', storeAuth.user["id"], 'notes')
            // notesCollectionRef = collection(db, 'users', 'q1QfFdwVCGRx1ODmZ6CpDUDS1VT2', 'notes')
            notesCollectionQuery = query(notesCollectionRef, orderBy('date', 'desc'))
            this.getNotes()
        },
        async getNotes() {
            this.notesLoaded = false

            // unsubscribe from any active listener
            if (getNotesSnapshot) getNotesSnapshot()

            getNotesSnapshot = onSnapshot(notesCollectionQuery, (querySnapshot) => {
                let notes = []
                querySnapshot.forEach((doc) => {
                    let note = {
                        id: doc.id,
                        content: doc.data().content,
                        date: doc.data().date
                    }
                    notes.push(note)
                })
                this.notes = notes
                this.notesLoaded = true
            })
        },
        clearNotes() {
            this.notes = []
            if (getNotesSnapshot) getNotesSnapshot()
        },
        async addNote(newNoteContent) {
            let currentDate = new Date().getTime(),
                date = currentDate.toString()

            await addDoc(notesCollectionRef, {
                content: newNoteContent,
                date: date
            })
        },
        async deleteNote(idToDelete) {
            await deleteDoc(doc(notesCollectionRef, idToDelete))
        },
        async updateNote(id, content) {
            let currentDate = new Date().getTime(),
                dateupdated = currentDate.toString()

            await updateDoc(doc(notesCollectionRef, id), {
                content: content,
                dateupdated: dateupdated
            })
        }
    },

    // ANTIGOS PODEM SERVIR PARA MAIS TARDE MYSQL
    // addNote(value) {
    //     let currentDate = new Date().getTime()
    //     let id = currentDate.toString()
    //
    //     let note = {
    //         id: id,
    //         content: value
    //     }
    //     this.notes.unshift(note)
    // },
    // deleteNote(idToDelete) {
    //     // console.log(idToDelete)
    //     this.notes = this.notes.filter(notaApagar => {
    //         return notaApagar.id !== idToDelete
    //     })
    // },
    // updateNote(id, content) {
    //     // console.log('id: ' + id)
    //     // console.log('content: ' + content)
    //
    //     let index = this.notes.findIndex(note => {
    //         return note.id === id
    //     })
    //     // console.log('i: ' + index)
    //
    //     this.notes[index].content = content
    // }

    getters: {
        getNoteContent: (state) => {
            // return state.notes[0].content
            return (registoID) => {
                // console.log('id from getter:' + id)
                return state.notes.filter(note => {
                    return note.id === registoID
                })[0].content
            }
        },
        totalNotesCount: (state) => {
            return state.notes.length
        },
        totalCharactersCount: (state) => {
            let count = 0
            state.notes.forEach(note => {
                count += note.content.length
            })
            return count
        }
    }
})
