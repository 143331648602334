import {defineStore} from "pinia";
import {signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, onAuthStateChanged} from "firebase/auth";
import {auth} from "@/js/firebase";
import { useStoreNotes } from '@/stores/StoreNotes'

export const useStoreAuth = defineStore('storeAuth', {
    state: () => {
        return {
            user: {}
        }
    },
    actions: {
        init() {
            const storeNotes = useStoreNotes()

            onAuthStateChanged(auth, (user) => {
                if (user) {
                    this.user.id = user.uid
                    this.user.email = user.email
                    this.router.push('/')
                    storeNotes.init()
                } else {
                    this.user = {}
                    this.router.replace('/auth')
                    storeNotes.clearNotes()
                }
            })
        },
        registerUser(credentials) {
            console.log('registerUser: ', credentials)
            createUserWithEmailAndPassword(auth, credentials.email, credentials.password)
                .then((userCredential) => {
                    // Signed in
                    // eslint-disable-next-line no-unused-vars
                    const user = userCredential.user;
                    // console.log(user)
                    // ...
                })
                .catch((error) => {
                    console.log('code: ', error.code)
                    console.log('error: ', error.message)
                    // const errorCode = error.code;
                    // const errorMessage = error.message;
                    // ..
                });
        },
        loginUser(credentials) {
            console.log('loginUser: ', credentials)
            signInWithEmailAndPassword(auth, credentials.email, credentials.password).then((userCredential) => {
                // eslint-disable-next-line no-unused-vars
                const user = userCredential.user
                // console.log('user: ', user)
            }).catch((error) => {
                console.log('code: ', error.code)
                console.log('error: ', error.message)
            })
        },
        logoutUser() {
            signOut(auth).then(() => {
                console.log('signOut')
            }).catch((error) => {
                console.log('error', error)
            })
        }
    }
})