<template>
  <div class="auth">

    <div class="tabs is-centered">
      <ul>
        <li
            :class="{ 'is-active' : !register}"
        >
          <a @click.prevent="register = false">Login</a>
        </li>
        <li
            :class="{ 'is-active' : register}"
        >
          <a @click.prevent="register = true">Register</a>
        </li>
      </ul>
    </div>

    <div class="card auth-form">
      <div class="card-content">

        <div class="title has-text-centered">
          {{ formTitle }}
        </div>

        <div class="content">

          <form @submit.prevent="onSubmit">
            <div class="field">
              <label class="label">Email</label>
              <div class="control">
                <input
                    v-model="credentials.email"
                    class="input"
                    type="email"
                    placeholder="e.g. alexsmith@gmail.com"
                >
              </div>
            </div>

            <div class="field">
              <label class="label">Password</label>
              <div class="control">
                <input
                    v-model="credentials.password"
                    class="input"
                    type="password"
                    placeholder="e.g Alex Smith"
                >
              </div>
            </div>

            <div class="field is-grouped is-grouped-right">
              <p class="control">
                <button class="button is-primary">
                  {{ formTitle }}
                </button>
              </p>
            </div>
          </form>

        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
// IMPORTS
import {computed, reactive, ref} from 'vue'
import {useStoreAuth} from "@/stores/storeAuth";


// STORE
const storeAuth = useStoreAuth()

// REGISTER
const register = ref(false)


// COMPUTED FORM TITLE
const formTitle = computed(() => {
  return register.value ? 'Register' : 'Login'
})

// CREDENTIALS
const credentials = reactive({
  email: '',
  password: ''
})

// SUBMIT
const onSubmit = () => {
  // console.log('Form Submited')
  if (!credentials.email || !credentials.password) {
    alert('Please enter an email and password!')
  } else {
    if (register.value) {
      console.log('register')
      storeAuth.registerUser(credentials)
    } else {
      console.log('login')
      storeAuth.loginUser(credentials)
    }
  }
}

</script>

<style>
.auth-form {
  max-width: 400px;
  margin: 0 auto;
}
</style>