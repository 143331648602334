<template>
  <div class="edit-notes">

    <AddEditNote
        v-model="noteContent"
        bgColor="link"
        ref="addEditNoteRef"
        placeHolder="Edit Note"
        labelText="Edit Note"
    >
      <template #buttons>

        <!--        <RouterLink-->
        <!--            to="/"-->
        <!--            class="button is-link is-info mr-2"-->
        <!--        >-->
        <!--          Cancel-->
        <!--        </RouterLink>-->

        <!--        <button-->
        <!--            @click="$router.push('/')"-->
        <!--            class="button is-link is-info mr-2"-->
        <!--        >-->
        <!--          Cancel-->
        <!--        </button>-->

        <button
            @click="$router.back"
            class="button is-link is-info mr-2"
        >
          Cancel
        </button>

        <button
            @click="handleSaveClicked"
            class="button is-link has-background-link"
            :disabled="!noteContent"
        >
          Save Note
        </button>

      </template>
    </AddEditNote>

  </div>
</template>


<script setup>
// IMPORTS
import {ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import AddEditNote from '@/components/notes/AddEditNote'
import {useStoreNotes} from "@/stores/StoreNotes";

// ROUTE
const route = useRoute()
const router = useRouter()

// STORE
// eslint-disable-next-line no-unused-vars
const storeNotes = useStoreNotes()

// NOTE
const noteContent = ref('')

// console.log(route.params.id)
noteContent.value = storeNotes.getNoteContent(route.params.id)

// handleSaveClicked
const handleSaveClicked = () => {
  // console.log('handleSaveClicked')
  // eslint-disable-next-line no-unused-vars
  // let payload = {
  //   id: route.params.id,
  //   content: noteContent.value
  // }
  // console.log(payload)

  storeNotes.updateNote(
      route.params.id,
      noteContent.value,
  )
  // depois de gravar redirect
  router.push('/')

}


</script>