<template>
  <div
      class="card mb-4">
    <header class="card-header">
      <button class="card-header-icon" aria-label="more options">
      <span class="icon">
        <i class="fas fa-angle-down" aria-hidden="true"></i>
      </span>
      </button>
    </header>
    <div class="card-content">
      <div class="content">
        {{ note['content'] }}
        <div class="columns is-mobile has-text-grey-light mt-2">
          <small class="column has-text-left is-size-7 is-italic">
            <small>Created {{ dataRegistoInserido }} </small>
            <span v-if="dataRegistoAlterado">
              <br>
                <small>Updated {{ dataRegistoAlterado }} </small>
            </span>
          </small>
          <small class="column has-text-right">{{ charLengthComputed }}</small>
        </div>
      </div>
    </div>
    <footer class="card-footer">

      <RouterLink
          :to="`/editNote/${note['id'] }`"
          class="card-footer-item"
          href="#"
      >
        Edit
      </RouterLink>

      <a
          @click.prevent="modals.deleteNote = true"
          href="#"
          class="card-footer-item"
      >
        Delete
      </a>
    </footer>

    <ModalDeleteNote
        v-if="modals.deleteNote"
        v-model="modals.deleteNote"
        :noteId="note['id']"
    >

    </ModalDeleteNote>

  </div>
</template>


<script setup>
// IMPORTS
import {computed, reactive} from "vue";
import ModalDeleteNote from "@/components/notes/ModalDeleteNote"
import {useDateFormat} from '@vueuse/core'


// DATAS
// const datahoje = useDateFormat(useNow(), 'YYYY-MM-DD (ddd)', { locales: 'en-US' })
const dataRegistoInserido = computed(() => {
  let dateRetiradaDB = new Date(parseInt(props.note["date"]))
  let dateRetirada = useDateFormat(dateRetiradaDB, 'DD-MMM-YYYY @ HH:mm') //return disto vem com aspas
  return dateRetirada["value"]
})

const dataRegistoAlterado = computed(() => {
  if (props.note["dateupdated"]) {
    let dateupdatedDB = new Date(parseInt(props.note["dateupdated"]))
    let dateupdated = useDateFormat(dateupdatedDB, 'DD-MMM-YYYY @ HH:mm') //return disto vem com aspas
    return dateupdated["value"]
  } else {
    return null
  }
})

// PROPS
/* eslint-disable */
const props = defineProps({
      note: {
        type: Object,
        required: true
      }
    }
)

// COMPUTED
const charLengthComputed = computed(() => {
  let conteudo = props.note["content"].length
  let termo = (conteudo > 1 ? 'characters' : 'character')
  return `${conteudo} ${termo}`
})

// MODALS
const modals = reactive({
  deleteNote: false
})

</script>
