// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";

import {getFirestore} from "firebase/firestore";
import {getAuth} from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC58LLuLtFPiNWwWh_qtVXqEHdyejeQtQc",
    authDomain: "notesaz-4528d.firebaseapp.com",
    projectId: "notesaz-4528d",
    storageBucket: "notesaz-4528d.appspot.com",
    messagingSenderId: "127596208959",
    appId: "1:127596208959:web:d4d204268b5a969997a98a",
    measurementId: "G-XWETMH1LZ5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// eslint-disable-next-line no-unused-vars
const analytics = getAnalytics(app);
const db = getFirestore(app)
const auth = getAuth(app);


export {
    db,
    auth
}