<template>
  <div class="notes">

    <AddEditNote
        v-model="newNote"
        ref="addEditNoteRef"
        placeHolder="Add a new Note"
    >
      <template #buttons>
        <button
            @click.prevent="addNote"
            :disabled="!newNote"
            class="button is-link has-background-success"
        >
          Add New Note
        </button>

      </template>
    </AddEditNote>

    <progress
        v-if="!storeNotes.notesLoaded"
        class="progress is-large is-success"
        max="100"
    />

    <template v-else>
      <NoteConteudo
          v-for="note in storeNotes.notes"
          :key="note.id"
          :note="note"
      />

      <div
          v-if="!storeNotes.notes.length"
          class="is-size-4 has-text-centered is-family-monospace py-6"
      >
          No notes here yet...
      </div>

    </template>


  </div>
</template>

<script setup>
// IMPORTS
import {ref} from 'vue'
import NoteConteudo from "@/components/notes/NoteConteudo";
import {useStoreNotes} from "@/stores/StoreNotes";
import AddEditNote from "@/components/notes/AddEditNote";
import {useWatchChars} from "@/use/useWatchChars";

// STORE
const storeNotes = useStoreNotes()

// NOTES
const newNote = ref('')
const addEditNoteRef = ref(null)

const addNote = () => {
  storeNotes.addNote(newNote.value)
  newNote.value = ''
  addEditNoteRef.value.focusTextArea()
}

// use
useWatchChars(newNote, 100)

</script>
